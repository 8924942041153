import React, { useRef, useEffect, useState } from 'react';
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import useWindowSize from "../hooks/useWindowSize";
// Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header"
import StickyHeader from "../components/stickyHeader";
import featureImage from "../images/feature_about.jpg";
import tabletFeatureImage from "../images/feature_about_tablet.jpg";
import mobileFeatureImage from "../images/feature_about_mobile.jpg";

const About = () => {
  const featureImgEl = useRef(null);
  const featureTitle = useRef(null);
  const featureSubTitle = useRef(null);
  const mainTitle = useRef(null);
  const teamList = useRef(null);
  const teamItems = useRef([]);
  teamItems.current = [];
  const [featureImg, setFeatureImg] = useState("");
  const [members, setMembers] = useState([]);
  const { width } = useWindowSize();

  useEffect(() => {
    handleFetchMembers();
    
    // Jumbotron animation
    gsap.from(featureImgEl.current, {
      duration: 0.8,
      autoAlpha: 0,
      y: 200,
      ease: "power3",
    });

    gsap.from(featureTitle.current, {
      duration: 1.5,
      autoAlpha: 0,
      y: 200,
      ease: "power3",
      delay: 0.5
    });
    gsap.from(featureSubTitle.current, {
      duration: 1.5,
      autoAlpha: 0,
      y: 200,
      ease: "power3",
      delay: width > 850 ? 1 : 0.75
    });

    // Content animation
    gsap.registerPlugin(ScrollTrigger);
    const mainContentTrigger = {
      trigger: mainTitle.current,
      start: "top center",
      end: "center center",
      endTrigger: teamList.current,
      toggleActions: "play none none reverse",

    };
    gsap.from(mainTitle.current, {
      duration: 1,
      autoAlpha: 0,
      y: 150,
      ease: "power3",
      scrollTrigger: mainContentTrigger
    });

    return () => {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (width > 850) {
      setFeatureImg(featureImage);
    } else if (width > 600) {
      setFeatureImg(tabletFeatureImage);
    } else {
      setFeatureImg(mobileFeatureImage);
    }
  }, [width]);

  const addToRefs = (el) => {
    if (el && !teamItems.current.includes(el)) {
      teamItems.current.push(el);
    }
  }

  const handleFetchMembers = () => {
    fetch("/api/get/member")
      .then(res => res.json())
      .then(data => {
        const { rtCode, rtData: { members } } = data;
        if (rtCode === "S00") {
          members.forEach(member => {
            setMembers(prev => ([
              ...prev,
              {
                memberId: member.id,
                memberName: member.memberName,
                memberTitle: member.memberTitle,
                memberAvatar: member.memberAvatar
              }
            ]));
          });
          // Animate after got data
          teamItems.current.forEach((el) => {
            gsap.from(el, {
              duration: 1,
              autoAlpha: 0,
              y: width > 850 ? 300 : 100,
              ease: "power3",
              delay: 0.5,
              scrollTrigger: {
                trigger: mainTitle.current,
                start: "top center",
                end: "center center",
                endTrigger: teamList.current,
                toggleActions: "play none none reverse",
              }
            })
          });
        }
      })
      .catch(err => console.error(err.message));
  }

  return (
    <Layout>
      <SEO title="About" />
      <Header>
        <div className="jumbotron d-flex">
          <div className="container about__feature">
            <img className="about__feature--image" src={featureImg} alt="About Coolso" ref={featureImgEl} />
            <div className="about__feature--content">
              <h2 className="heading__primary line-height-1 text-white about__feature--title" ref={featureTitle}>We are Cool<span className="about__feature--title-part">So</span></h2>
              <p className="paragraph--intro about__feature--text" ref={featureSubTitle}>We strive to redefine Human Computer Interface with our own patented technology.</p>
            </div>
          </div>
        </div>
      </Header>
      <StickyHeader />
      <section className="content">
        <div className="content__section container about__intro">
          <h1 className="heading__secondary about__intro-title" ref={mainTitle}>The future is powered<br />by Innovation.<br />Innovations are<br />powered by people.</h1>
          <ul className="about__intro-list" ref={teamList}>
            {members.map((member) => (
              <li className="about__intro-item" key={member.memberId} ref={addToRefs}>
                <div className="about__intro-item--image">
                  <img src={member.memberAvatar} alt={member.memberName} />
                </div>
                <h3 className="about__intro-item--name">{member.memberName}</h3>
                <p className="about__intro-item--title">{member.memberTitle}</p>
              </li>
            ))}
          </ul>
        </div>
      </section>
    </Layout>
  )
}

export default About
